<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="">Transaksi TELLER</strong>
        <!-- <a-button @click="componentKeyDatatable++">generateValidasi</a-button> -->
      </template>
      <div class="row">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 pt-2 pb-2">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label class="ml-4 mr-2">No. Rekening</label>
              <cleave
                :readOnly="false"
                :options="{ delimiter: '.', blocks: [2, 2, 6, 2] }"
                class="ant-input"
                :raw="false"
                style="width: 150px;"
                v-model.lazy="norekening"
                placeholder="Ketik No Rekening"
                v-on:keydown.enter.native="checkRekening"
              />
              <!-- <a-select
                label-in-value
                show-search
                :value="selectedRekening"
                placeholder="Ketik No Rekening"
                style="width: 150px;"
                :filter-option="false"
                :not-found-content="fetching ? 'Searching...' : 'Not Found!'"
                @search="fetchUser"
                @change="(e) => handleChange(e, 'selectedRekening')"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="(d, index) in dataRekening" :key="index">
                  {{ d.rekening + ' - ' + d.nama }}
                  {{ d.rekening }}
                </a-select-option>
              </a-select> -->
              <a-select
                label-in-value
                show-search
                class="ml-2"
                :value="selectedName"
                placeholder="Ketikkan Nama Nasabah"
                style="width: 250px;"
                :filter-option="false"
                :not-found-content="fetching ? 'Searching...' : 'Not Found!'"
                @search="fetchUser"
                @change="(e) => handleChange(e, 'selectedName')"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="(d, index) in dataRekening" :key="index">
                  <!-- {{ d.rekening + ' - ' + d.nama }} -->
                  {{ d.nama }}
                </a-select-option>
              </a-select>
              <!-- <a-input placeholder="Nomor Rekening" style="width: 150px;" v-model="norekening" @keydown.enter="getAllData"/> -->
              <!-- <label class="ml-4 mr-2">Cari Nasabah: </label>
              <a-input placeholder="Cari Nasabah" style="width: 200px;"/> -->
              <!-- <a-button class="ml-2" @click="show">Cari Nasabah</a-button> -->
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <label class="ml-4 mr-2">Tgl Transaksi: </label>
              <a-date-picker
                style="width: 100px;"
                v-model="tanggalTransaksi"
                :allowClear="false"
                format="DD-MM-YYYY"
                @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['tanggalTransaksi'])"
              />
              <!-- <a-button class="ml-2">Rek Lama</a-button> -->
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <label class="ml-4 mr-2">Nama Nasabah: </label>
              <a-tag color="#2db7f5">
                {{ namaprekening !== '' ? namaprekening : 'Belum memilih nasabah' }}
              </a-tag>
              <!-- <a-input placeholder="Nama Nasabah" v-model="namaprekening" :readOnly="true" style="width: 250px;"/> -->
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <label class="ml-4 mr-2">Alamat Nasabah: </label>
              <a-tag color="#2db7f5">
                {{ alamatprekening !== '' ? alamatprekening : 'Belum memilih nasabah' }}
              </a-tag>
              <!-- <a-input placeholder="Alamat Nasabah" v-model="alamatprekening" :readOnly="true" style="width: 350px;"/> -->
            </div>
          </div>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 pt-2" style="padding-left: unset !important;">
          <div class="row">
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5" style="padding-right: unset !important;">
              <a-card style="height: 140px">
                <center style="margin-top: 30%; cursor: default;">
                  No Image Data
                </center>
              </a-card>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7" style="padding-left: unset !important;">
              <a-card style="height: 140px">
                <center style="margin-top: 30%; cursor: default;">
                  No Image Data
                </center>
              </a-card>
            </div>
          </div>
        </div>
        <a-divider type="horizontal" style="margin: 10px !important;"/>
        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 pt-2 pb-2">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label class="ml-3">Kode Transaksi: </label>
              <a-select class="ml-2" v-model="kodetransaksi" placeholder="Setoran Tunai" style="width: 375px;" @change="changeKodeTransaksi">
                <a-select-option v-for="(data, index) in masterKodeTransaksi" :key="index" :value="data.kode">{{ data.kode + ' - ' + data.keterangan + ' - ' + data.dk }}</a-select-option>
              </a-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label class="ml-3">Keterangan: </label>
              <a-input class="ml-2" placeholder="Keterangan" style="width: 390px;" v-model="keterangan"/>
            </div>
          </div>
        </div>
        <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
          <center>
            <div class="row">
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <label class="mt-1 float-right">Saldo Awal: </label>
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                <cleave
                  :readOnly="true"
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px; text-align: right;"
                  v-model="saldoprekening"
                  placeholder="Saldo Awal"
                />
                <!-- <a-input class="" placeholder="Saldo Awal" v-model="saldoprekening" style="width: 200px;"/> -->
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <label class="mt-1 float-right">Mutasi: </label>
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                <cleave
                  :readOnly="false"
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px; text-align: right;"
                  v-model="mutasi"
                  placeholder="Masukan Mutasi"
                  v-on:keyup.enter.native="saveMutasi"
                />
                <!-- <a-input class="" placeholder="Mutasi" style="width: 200px;"/> -->
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <label class="mt-1 float-right">Saldo Akhir: </label>
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                <a-input v-model="saldoakhir" style="width: 200px; text-align: right;" placeholder="Saldo Akhir"></a-input>
                <!-- <cleave
                  :readOnly="false"
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="saldoakhir"
                  placeholder="Saldo Akhir"
                /> -->
                <!-- <a-input class="" placeholder="Saldo Akhir" style="width: 200px;"/> -->
              </div>
            </div>
          </center>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-button class="btn btn-outline-primary btn-sm mr-4 float-right" style="width: 100px;" @click="saveMutasi">Simpan</a-button>
        </div>
        <!-- <a-divider type="horizontal" style="margin: 10px !important;"/> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <!-- style="margin-left: -26px; margin-right: -26px; margin-top: -21px;" -->
          <a-table
            :key="componentKeyDatatable"
            :columns="columns"
            :dataSource="data"
            size="small"
            :scroll="{ y: 400 }"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 500,
              hideOnSinglePage: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <span v-if="column.dataIndex === 'name'">
                  {{ text }}
                </span>
                <span v-else>
                  {{ text }}
                </span>
              </template>
            </template>
            <template slot="cetak" slot-scope="text, record">
              <a-button class="btn btn-outline-success" @click="reGenerateValidasi(record)">
                <a-icon type="printer" />
              </a-button>
            </template>
            <label slot="tgl" slot-scope="text">
              {{ formatTanggal(text) }}
            </label>
            <template slot="jumlah" slot-scope="text, record">
              {{ record.debet !== 0 ? formatCurrency(record.debet) : formatCurrency(record.kredit) }}
            </template>
          </a-table>
        </div>
      </div>
    </a-card>
    <a-modal title="Cetak Buku" v-model="modalCetakBuku.visible" :width="800" :dialogStyle="{ top: '20px' }">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="modalCetakBuku.visible = false">
          Cancel
        </a-button>
        <a-button class="btn btn-outline-primary" @click="generateBuku">
          Cetak
        </a-button>
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <label class="mt-1" for="betweenTgl">Antara Tanggal: </label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                  <a-date-picker
                    class="mr-2"
                    style="width: 100px;"
                    v-model="modalCetakBuku.tgl1"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['modalCetakBuku', 'tgl1'])"
                  />
                  -
                  <a-date-picker
                    class="ml-2"
                    style="width: 100px;"
                    v-model="modalCetakBuku.tgl2"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['modalCetakBuku', 'tgl2'])"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Saldo Awal: </label>
              <a-input class="ml-2" style="width: 200px; text-align: right;" v-model="modalCetakBuku.saldoAwal" :readOnly="true"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <label for="betweenTgl" class="mt-1">No. Rekening: </label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                  <a-input class="" v-model="modalCetakBuku.noRekening"></a-input>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAkhir">Saldo Akhir: </label>
              <a-input class="ml-2" style="width: 200px; text-align: right;" v-model="modalCetakBuku.saldoAkhir" :readOnly="true"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl" class="mt-1">Nama: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input class="" v-model="modalCetakBuku.nama" :readOnly="true"></a-input>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Baris Cetak: </label>
              <a-input class="ml-2" style="width: 40px;" v-model="modalCetakBuku.barisCetak"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl" class="mt-1">Alamat: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input class="" v-model="modalCetakBuku.alamat" :readOnly="true"></a-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
          <a-button :class="modalCetakBuku.selectallmode ? 'btn btn-outline-primary btn-sm mb-2' : 'btn btn-outline-danger btn-sm mb-2'" @click="selectAllModalCetak">{{modalCetakBuku.selectallmode ? 'Select All' : 'Diselect All'}}</a-button>
          <a-table
            :columns="modalCetakBuku.columns"
            :dataSource="modalCetakBuku.datatable"
            style="margin-left: -24px; margin-right: -24px;"
            size="small"
            :key="componentKeyDatatable"
            :scroll="{ y: 200 }"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 200,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['100', '200', '300'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <template slot="mutasi" slot-scope="text, record">
              {{ record.debet === 0 ? formatCurrency(record.kredit) : formatCurrency(record.debet) }}
            </template>
            <template slot="saldo" slot-scope="text, record">
              {{ formatCurrency(record.saldo) }}
            </template>
            <template slot="selected" slot-scope="text, record">
              <a-checkbox :key="componentKey" :checked="record.selected" @change="(e) => changedSelectedModal(e, record)" />
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
    <!-- <modal name="carinasabahmodal" :maskClosable="false" :draggable="true" :footer="null"></modal> -->
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import debounce from 'lodash/debounce'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import JSPDF from 'jspdf'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  components: {
    cleave: Cleave,
  },
  created () {
    this.getMaster()
    if (this.settings.selectedRekening !== '') {
      this.norekening = this.settings.selectedRekening
      this.checkRekening()
    }
  },
  watch: {
    'modalCetakBuku.visible'(nv, ov) {
      if (!nv) {
        console.log('nv', nv)
        this.resetForm()
        this.getAllData()
      }
    },
    'settings.selectedRekening'() {
      this.norekening = this.settings.selectedRekening
      this.checkRekening()
    },
    // Alternate component "Cleave" tidak dapat menggunakan @input ataupun @change
    mutasi(newValue, oldValue) {
      // console.log('newValue', newValue)
      // console.log('oldValue', oldValue)
      this.mutasiChange()
    },
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      tanggalTransaksi: moment(),
      selectedRekening: [],
      selectedName: [],
      norekening: '',
      namaprekening: '',
      alamatprekening: '',
      kodetransaksi: '',
      rekeningjurnal: '',
      keterangan: '',
      saldoprekening: '',
      mutasi: '',
      saldoakhir: '',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      fetching: false,
      dataRekening: [],
      modalCetakBuku: {
        responseAPI: null,
        changedData: [],
        visible: false,
        tgl1: moment(),
        tgl2: moment(),
        noRekening: '',
        nama: '',
        alamat: '',
        saldoAwal: '',
        saldoAkhir: '',
        barisCetak: 0,
        selectallmode: false,
        columns: [
          {
            title: '#',
            width: 50,
            dataIndex: 'selected',
            scopedSlots: {
              customRender: 'selected',
            },
          },
          {
            title: 'Tanggal',
            dataIndex: 'tgl',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.tgl.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'SD',
            dataIndex: 'kodetransaksi',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.kodetransaksi.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Mutasi',
            dataIndex: 'mutasi',
            scopedSlots: {
              customRender: 'mutasi',
            },
            align: 'right',
          },
          {
            title: 'Saldo',
            dataIndex: 'saldo',
            scopedSlots: {
              customRender: 'saldo',
            },
            align: 'right',
          },
          {
            title: 'Username',
            dataIndex: 'username',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.username.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
        ],
        datatable: [],
      },
      carinasabahmodal: {
        visible: false,
        column: [],
        datatable: [],
      },
      columns: [
        {
          title: 'Cetak',
          width: 50,
          scopedSlots: {
            customRender: 'cetak',
          },
        },
        {
          title: 'Tanggal Transaksi',
          key: 'tgl',
          scopedSlots: { customRender: 'tgl' },
        },
        {
          title: 'Nomor Transaksi',
          dataIndex: 'faktur',
          scopedSlots: { customRender: 'nomortransaksi' },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: { customRender: 'keterangan' },
        },
        {
          title: 'D/K',
          dataIndex: 'dk',
          width: 70,
          scopedSlots: { customRender: 'dk' },
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah',
          scopedSlots: { customRender: 'jumlah' },
          align: 'right',
        },
        // {
        //   title: 'Akses',
        //   dataIndex: 'privileges',
        //   scopedSlots: { customRender: 'privileges' },
        // },
      ],
      data: [],
      masterKodeTransaksi: [],
      componentKey: 0,
      componentKeyDatatable: 0,
      onsearch: false,
    }
  },
  methods: {
    async checkRekening() {
      if (this.norekening.length === 15) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet('mutasitabungan/cari_rekening/' + this.norekening.toString())
        if (res) {
          if (res.data.length !== 0) {
            // lou.shownotif('Ditemukan!', 'Data telah ditemukan!', 'info')
            this.dataRekening = res.data
            var value = this.dataRekening.findIndex(x => x.rekening === this.norekening)
            var dataTarget = this.dataRekening[value]
            var data = {
              selectedName: { key: value, label: dataTarget.nama },
              selectedRekening: { key: value, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)
          } else {
            lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
          }
        } else {
          // lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
        }
      }
    },
    resetForm() {
      var data = {
        rekeningjurnal: '',
        norekening: '',
        namaprekening: '',
        alamatprekening: '',
        saldoprekening: '',
        dataRekening: [],
        fetching: false,
        selectedRekening: [],
        selectedName: [],
        kodetransaksi: '',
        keterangan: '',
        mutasi: '',
        saldoakhir: '',
      }
      var datamodal = {
        responseAPI: null,
        changedData: [],
        visible: false,
        tgl1: moment(),
        tgl2: moment(),
        noRekening: '',
        nama: '',
        alamat: '',
        saldoAwal: '',
        saldoAkhir: '',
        barisCetak: 0,
        selectallmode: false,
        datatable: [],
      }
      Object.assign(this, data)
      Object.assign(this.modalCetakBuku, datamodal)
    },
    selectAllModalCetak() {
      this.modalCetakBuku.datatable.forEach((element) => {
        element.selected = this.modalCetakBuku.selectallmode
      })
      if (this.modalCetakBuku.selectallmode) {
        this.modalCetakBuku.changedData = this.$g.clone(this.modalCetakBuku.datatable)
      } else {
        this.modalCetakBuku.changedData = []
      }
      this.modalCetakBuku.selectallmode = !this.modalCetakBuku.selectallmode
    },
    changedSelectedModal(e, record) {
      // console.log('record', record.selected)
      // console.log('e', e)
      var checked = e.target.checked
      // console.log('record', record.selected)
      record.selected = checked
      var target = this.modalCetakBuku.changedData.findIndex((x) => x.id === record.id)
      if (target < 0) {
        this.modalCetakBuku.changedData.push(record)
      } else {
        if (checked) {
          this.modalCetakBuku.changedData[target].selected = checked
        } else {
          this.modalCetakBuku.changedData.splice(target, 1)
        }
      }
      this.componentKey++
      // console.log('this.modalCetakBuku.changedData', this.modalCetakBuku.changedData)
      // console.log('checked', checked)
    },
    async getDatatableModalBuku() {
      var params = {
        rekening: this.modalCetakBuku.noRekening,
        tgl_awal: this.modalCetakBuku.tgl1.format('YYYY-MM-DD'),
        tgl_akhir: this.modalCetakBuku.tgl2.format('YYYY-MM-DD'),
      }
      var res = await lou.customUrlPost('mutasitabungan/bukutabungan', params, false)
      if (res) {
        this.modalCetakBuku.responseAPI = res.data
        this.modalCetakBuku.datatable = res.data.mutasitabungan
        this.modalCetakBuku.saldoAwal = lou.curency(this.modalCetakBuku.responseAPI.saldoawal)
        this.modalCetakBuku.saldoAkhir = lou.curency(this.modalCetakBuku.responseAPI.saldoakhir)
        this.modalCetakBuku.barisCetak = this.modalCetakBuku.responseAPI.bariscetak
        this.modalCetakBuku.datatable.forEach((element) => {
          element.selected = element.statusprinter === '0'
        })
      }
    },
    modalCetakBukuOpenaClose() {
      this.modalCetakBuku.visible = !this.modalCetakBuku.visible
      if (this.modalCetakBuku.visible) {
        this.modalCetakBuku.noRekening = this.norekening
        this.modalCetakBuku.nama = this.namaprekening
        this.modalCetakBuku.alamat = this.alamatprekening
        this.getDatatableModalBuku()
        // this.modalCetakBuku.datatable.forEach((element) => {
        //   element.selected = element.statusprinter === '0'
        // })
      }
    },
    changeDateInput(dateMoment, dateString, from) {
      if (dateMoment !== null && from[0] === 'modalCetakBuku') {
        this.getDatatableModalBuku()
      }
    },
    changeKodeTransaksi() {
      var targetIndex = this.masterKodeTransaksi.findIndex(x => x.kode === this.kodetransaksi)
      var dataTarget = this.dataRekening[this.selectedRekening.key]
      this.keterangan = this.masterKodeTransaksi[targetIndex].keterangan + ' a.n ' + dataTarget.nama
      this.mutasiChange()
    },
    mutasiChange() {
      if (this.mutasi !== null && this.kodetransaksi !== '') {
        var targetKode = this.masterKodeTransaksi.findIndex(x => x.kode === this.kodetransaksi)
        var total = 0
        // console.log('this.mutasi', this.mutasi)
        // console.log('this.masterKodeTransaksi[targetKode].dk', this.masterKodeTransaksi[targetKode].dk)
        if (this.masterKodeTransaksi[targetKode].dk === 'K') {
          total = parseInt(this.saldoprekening) + parseInt(this.mutasi)
          // console.log('this.mutasi', this.mutasi)
        } else {
          total = parseInt(this.saldoprekening) - parseInt(this.mutasi)
          // console.log('this.mutasi', this.mutasi)
        }
        this.saldoakhir = isNaN(total) ? 0 : lou.curency(total)
        // console.log('this.mutasi', this.mutasi)
        // console.log('total', total)
      }
    },
    async getMaster() {
      var res = await lou.customUrlGet('mutasitabungan/kodetransaksi')
      if (res) {
        this.masterKodeTransaksi = res.data
      }
      var resp = await lou.customUrlGet('mutasitabungan/today')
      if (resp) {
        this.data = resp.data
      }
    },
    async getAllData() {
      var resp = await lou.customUrlGet('mutasitabungan/today')
      if (resp) {
        this.data = resp.data
      }
    },
    saveMutasi() {
      if (this.mutasi !== '' && this.mutasi !== '0' && this.saldoakhir !== '' && this.saldoakhir !== '0') {
        Modal.confirm({
          title: 'Simpan?',
          content: 'Apakah anda ingin menyimpan data?',
          okText: 'Yes',
          okType: 'primary',
          cancelText: 'No',
          onOk: async () => {
            var targetKode = this.masterKodeTransaksi.findIndex(x => x.kode === this.kodetransaksi)
            var fd = {
              tgl: this.tanggalTransaksi.format('YYYY-MM-DD'),
              kodetransaksi: this.masterKodeTransaksi[targetKode].kode,
              rekeningjurnal: this.rekeningjurnal,
              dk: this.masterKodeTransaksi[targetKode].dk,
              rekening: this.norekening,
              mutasi: this.mutasi,
              username: this.user.name,
              keterangan: this.keterangan,
              jenisslip: null,
              noslip: null,
            }
            var ntotal = this.saldoakhir.replaceAll(',', '')
            fd.jumlah = parseInt(ntotal)
            if (this.masterKodeTransaksi[targetKode].dk === 'K') {
              fd.kredit = parseInt(this.mutasi)
            } else {
              fd.debet = parseInt(this.mutasi)
            }
            // console.log('fd', fd)
            var res = await lou.customUrlPost('mutasitabungan', fd, true)
            if (res) {
              this.getAllData()
              Modal.confirm({
                title: 'Cetak Validasi?',
                content: 'Cetak validasi tabungan?',
                okText: 'Yes',
                okType: 'primary',
                cancelText: 'No',
                onOk: () => {
                  this.generateValidasi(res)
                  this.nextStep()
                },
                onCancel: () => {
                  this.nextStep()
                // console.log('Cancel')
                },
              })
            }
          },
          onCancel: () => {
          // console.log('Cancel')
          },
        })
      } else {
        if (this.mutasi !== '' && this.mutasi !== '0') {
          lou.shownotif('Mutasi!', 'Nominal Mutasi masih kosong atau 0')
        }
        if (this.saldoakhir !== '' && this.saldoakhir !== '0') {
          lou.shownotif('Saldo Akhir!', 'Saldo akhir masih kosong atau 0')
        }
      }
    },
    nextStep() {
      Modal.confirm({
        title: 'Cetak Buku?',
        content: 'Cetak di buku tabungan?',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: () => {
          this.modalCetakBukuOpenaClose()
        },
        onCancel: () => {
          this.resetForm()
          this.getAllData()
          // console.log('Cancel')
        },
      })
    },
    async reGenerateValidasi(record) {
      var res = await lou.customUrlGet('mutasitabungan/validasi/' + record.id)
      // doc.text(string/array[string], marginleft, margintop)
      if (res) {
        var leftMargin = 20
        var topMargin = 50
        var splitSize = 160 + leftMargin
        var data = res.data
        var time = data.time.toString().replace('T', ' ')
        var time1 = time.substring(0, 18)
        const doc = new JSPDF()
        doc.setFontSize(10)
        var theString = data.faktur + '  ' + moment(data.tgl, 'YYYY-MM-DD').format('DD-MM-YY') + '  ' + moment(time1, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YY HH:mm:ss') + '  ' + data.rekening
        var theString1 = data.username + '  ' + data.kodetransaksi + '  ' + data.namatransaksi + '  ' + data.dk + ' Rp  ' + lou.curency(data.jumlah) + ' #'
        var splitText = doc.splitTextToSize(theString, splitSize)
        var splitText1 = doc.splitTextToSize(theString1, splitSize)
        doc.text(splitText, leftMargin, topMargin)
        doc.text(splitText1, leftMargin, topMargin + 5)
        // var filename = 'Validasi'
        doc.output('dataurlnewwindow')
      }
    },
    generateValidasi(res) {
      // doc.text(string/array[string], marginleft, margintop)
      var leftMargin = 20
      var topMargin = 50
      var splitSize = 160 + leftMargin
      var data = res.data
      var time = data.time.toString().replace('T', ' ')
      var time1 = time.substring(0, 18)
      const doc = new JSPDF()
      doc.setFontSize(10)
      var theString = data.faktur + '  ' + moment(data.tgl, 'YYYY-MM-DD').format('DD-MM-YY') + '  ' + moment(time1, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YY HH:mm:ss') + '  ' + data.rekening
      var theString1 = data.username + '  ' + data.kodetransaksi + '  ' + data.namatransaksi + '  ' + data.dk + ' Rp  ' + lou.curency(data.jumlah) + ' #'
      var splitText = doc.splitTextToSize(theString, splitSize)
      var splitText1 = doc.splitTextToSize(theString1, splitSize)
      doc.text(splitText, leftMargin, topMargin)
      doc.text(splitText1, leftMargin, topMargin + 5)
      // var filename = 'Validasi'
      doc.output('dataurlnewwindow')
    },
    async generateBuku() {
      this.modalCetakBuku.changedData = []
      this.modalCetakBuku.datatable.forEach(element => {
        if (element.selected) {
          this.modalCetakBuku.changedData.push(element)
        }
      })
      if (this.modalCetakBuku.changedData.lenght === 0) {
        lou.shownotif('Data', 'Tidak memilih data untuk di cetak!')
      } else {
        const doc = new JSPDF()
        doc.setFontSize(10)
        var leftMargin = 2
        var topMargin = 27
        // console.log('this.modalCetakBuku', this.modalCetakBuku.changedData)
        var nourut = this.modalCetakBuku.barisCetak !== 0 ? this.modalCetakBuku.barisCetak.toString() : 1
        var tambahanjarak = 5 * this.modalCetakBuku.barisCetak
        var jarak = 0 + tambahanjarak
        for (let i = 0; i < this.modalCetakBuku.changedData.length; i++) {
          const element = this.modalCetakBuku.changedData[i]
          // console.log('element', element)
          doc.text(nourut.toString(), leftMargin, topMargin + jarak)
          doc.text(moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'), leftMargin + 8, topMargin + jarak)
          doc.text(element.kodetransaksi.toString(), leftMargin + 40, topMargin + jarak)
          var mutasi = element.debet === 0 ? lou.curency(element.kredit) : lou.curency(element.debet)
          doc.text(mutasi.toString() + '.00', leftMargin + 60, topMargin + jarak)
          doc.text(lou.curency(element.jumlah).toString() + '.00', leftMargin + 100, topMargin + jarak)
          doc.text(element.username.toString(), leftMargin + 140, topMargin + jarak)
          nourut++
          jarak += 5
        }
        // var filename = 'Buku'
        doc.output('dataurlnewwindow')
        var datapost = []
        this.modalCetakBuku.changedData.forEach(element => {
          datapost.push({ id: element.id, statusprinter: '1' })
        })
        var res = await lou.customUrlPost('mutasitabungan/statuscetak', datapost)
        if (res) {
          this.modalCetakBuku.datatable.forEach(element => {
            var targetIndex = this.modalCetakBuku.changedData.findIndex(x => x.id === element.id)
            if (targetIndex) {
              element.statusprinter = '1'
              element.selected = false
            }
          })
          this.componentKeyDatatable++
          this.componentKey++
          this.modalCetakBuku.barisCetak += this.modalCetakBuku.changedData.length
        }
      }
    },
    async fetchUser(value) {
      // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet('mutasitabungan/cari?Nama=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet('mutasitabungan/cari_rekening/' + nvalue1)
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
          // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    handleChange(value, from) {
      // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        var data = {
          selectedName: value,
          selectedRekening: value,
          rekeningjurnal: dataTarget.rekening,
          norekening: dataTarget.rekening,
          namaprekening: dataTarget.nama,
          alamatprekening: dataTarget.alamat,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    formatTanggal(v) {
      var ntgl = v.tgl.substring(0, 10)
      // console.log('ntgl', ntgl)
      return moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style>

</style>
